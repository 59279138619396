import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faDoorOpen, faShieldAlt, faBalanceScale, faUtensils, faExchangeAlt, faStepForward } from '@fortawesome/free-solid-svg-icons'
import './Programme.css'

export default function Programme() {
 return (
    <section id="digiContentProgramme">

        <h1 className="digiTitreGauche">Le Programme de la conférence</h1>

        <div className="digiContentPaddingLeftRight">
            <h3 className="h2">Le 14 octobre 2021</h3>
            <h2>Journée d'information et d'échange à destination des professionnels</h2>
            <p><strong>Evènement : </strong> Protection judiciaire des majeurs vulnérables</p>
            <p>Dans un contexte de réforme, une journée pour mieux appréhender le cadre d'intervention et les missions des mandataires judiciaires : champs d'actions, limites... afin d'optimiser l'accompagnement des personnes vulnérables.</p>
        </div>

        <section className="digiProgramme">
            <ul>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faCoffee} size="lg"/></span>
                    <strong className="digiHoraire">08h45</strong>
                    <span className="digiProgrammeItem">Accueil Café</span></li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faDoorOpen} size="lg" /></span>
                    <strong className="digiHoraire">09h00</strong>
                    <span className="digiProgrammeItem">
                        Ouverture de la journée
                        <br/><strong className="small">Services de l'Etat & Justice</strong>
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faShieldAlt} size="lg" /></span>
                    <strong className="digiHoraire">09h30</strong>
                    <span className="digiProgrammeItem">
                        La démarche de mise sous protection
                        <br/><strong className="small">M. Patrice ORLHAC, Association tutélaire de Lozère (ATL)</strong>
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faShieldAlt} size="lg" /></span>
                    <strong className="digiHoraire">10h00</strong>
                    <span className="digiProgrammeItem">
                        Les mesures de protection, contours et limites des missions des mandataires
                        <br/><strong className="small">M. Frédéric ITIER, MJPM</strong>
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faBalanceScale} size="lg" /></span>
                    <strong className="digiHoraire">11h00</strong>
                    <span className="digiProgrammeItem">
                        Ethique et accompagnement
                        <br/><strong className="small">M. Frédéric ITIER, MJPM</strong>
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faUtensils} size="lg" /></span>
                    <strong className="digiHoraire">12h30</strong>
                    <span className="digiProgrammeItem">
                        Restauration libre à proximité
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faExchangeAlt} size="lg" /></span>
                    <strong className="digiHoraire">13h30</strong>
                    <span className="digiProgrammeItem">
                        Projection de témoignages puis échanges et débat autour de différents champs d'intervention des MJPM
                        <br/><strong className="small">Animé par M. Bruno FOUCARD</strong>
                    </span>
                </li>
                <li>
                    <span className="digiPicto"><FontAwesomeIcon icon={faStepForward} size="lg" /></span>
                    <strong className="digiHoraire">16h30</strong>
                    <span className="digiProgrammeItem">Clôture</span>
                </li>
            </ul>
        </section>

    </section>
 )}