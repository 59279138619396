import React from 'react'

export default function Logo( props ) {

    const {file, path, alt, width} = props
    const fileName      = file ? file : 'logo-siao-48-lozere.png'
    const pathToFile    = path ? path : '/assets/images/'
    const altText       = alt ? alt : ''
    const imgWidth      = width ? width : '100%'

 return (
        <img src={pathToFile + fileName} alt={altText} width={imgWidth} />
 )}