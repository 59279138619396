import React from 'react'
import ChatBox from "./Chat/ChatBox";
import JwplayerEmbedVideo from "./Videos/JwplayerEmbedVideo";
import Countdown from "react-countdown";
import ButtonDiscoverEvent from "./Button/ButtonDiscoverEvent";

export default function Directs() {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <>
                    <div className="col-xl-6 offset-xl-2 p-xl-0">
                        <JwplayerEmbedVideo />
                    </div>
                    <div className="col-xl-4 m-xl-0 p-xl-0">
                        <ChatBox />
                    </div>
                </>
            )
        } else {
            // Render a countdown
            return (
                <div className="col-xl-9 offset-xl-3 p-xl-0 digiCountdown">
                    <div>{days}<span>j</span> {hours}<span>h</span> {minutes}<span>m</span> {seconds}<span>s</span></div>
                    <p className={"mb-4"}>La conférence débutera le 14 octobre 2021 à 9h00</p>
                    <ButtonDiscoverEvent
                        date={"14 octobre 2021"}
                        label={"Découvrir l'évènement"}
                        to={"/programme"}
                    />
                </div>
            )
        }
    };

 return (
     <section id="digiContentDirects">



         <h1 className="digiTitreGauche">Suivre la conférence</h1>

         {/*<div className="row d-flex m-0 mb-5 p-0">*/}

         {/*    <div className="col-xl-6 offset-xl-2 p-xl-0">*/}
         {/*        <JwplayerEmbedVideo />*/}
         {/*    </div>*/}
         {/*    <div className="col-xl-4 m-xl-0 p-xl-0">*/}
         {/*        <ChatBox />*/}
         {/*    </div>*/}

         {/*</div>*/}

         <div className="row d-flex m-0 mb-5 p-0">

             {/*<Countdown date={'2021-10-14T09:00:00'} renderer={renderer} />*/}
             <Countdown date={'2021-10-08T09:00:00'} renderer={renderer} />
             {/*<Countdown date={'2021-09-29T09:17:00'} renderer={renderer} />*/}
             {/*<Countdown date={Date.now() + 9000} renderer={renderer} />*/}

         </div>

     </section>
 )}