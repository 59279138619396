import React from 'react'
import ChatBox from "./Chat/ChatBox";
import JwplayerEmbedReplay from "./Videos/JwplayerEmbedReplay";

export default function Replays( props ) {
 return (
     <section id="digiContentReplays">

         <h1 className="digiTitreGauche">Le replay de la conférence</h1>


         <div className="digiContentPaddingLeftRight mb-5">
             <p>Retrouvez le replay de la journée d'informations et d'échanges sur la protection des majeurs vulnérables.</p>
             <JwplayerEmbedReplay />
         </div>

         <div className="row d-flex m-0 mb-5 p-0">


             {/*<div className="col-xl-6 offset-xl-2 p-xl-0">*/}
             {/*    <JwplayerEmbedVideo />*/}
             {/*</div>*/}
             {/*<div className="col-xl-4 m-xl-0 p-xl-0">*/}
             {/*    <ChatBox />*/}
             {/*</div>*/}

         </div>

     </section>
 )}