import React from 'react'

export default function JwplayerEmbedReplay() {

    let styleContainerIframe = {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
    }

    let styleIframe = {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        background: false,
    }

    return (


    <div itemScope itemType="https://schema.org/VideoObject">
        <meta itemProp="uploadDate" content="Fri Nov 12 2021 20:36:00 GMT+0100 (heure normale d’Europe centrale)"/>
        <meta itemProp="name" content="Siao"/>
        <meta itemProp="description"
              content="Journée d&#39;informations et d&#39;échanges sur la protection des majeurs vulnérables. SIAO Lozère"/>
        <meta itemProp="duration" content="P0Y0M0DT5H43M9S"/>
        <meta itemProp="thumbnailUrl" content="https://content.jwplatform.com/thumbs/7PPyj0Ah-1920.jpg"/>
        <meta itemProp="contentUrl" content="https://content.jwplatform.com/videos/7PPyj0Ah-O8tx8gto.mp4"/>
        <div style={styleContainerIframe}>
            <iframe src="https://cdn.jwplayer.com/players/7PPyj0Ah-BDfw21X2.html" width="100%" height="100%"
                    frameBorder="0" scrolling="auto" title="Siao" style={styleIframe} allowFullScreen></iframe>
        </div>
    </div>


    )
}