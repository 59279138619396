import React from 'react'
import './ButtonAction.css'
import {NavLink} from "react-router-dom";

export default function ButtonDiscoverEvent(props ) {

    const {date, label, to} = props;

 return (
    <div className={"digiButtonAction w-100"}>
        <NavLink exact activeClassName="current" to={to}  className="w-100">
            {date}<br/>
            <span>{label}</span>
        </NavLink>
    </div>
 )}