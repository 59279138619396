import React from 'react'
import SidebarLeft from "../SidebarLeft/SidebarLeft";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import Programme from "../Programme";
import Directs from "../Directs";
import Replays from "../Replays";

import './LayoutPage.css';
import Footer from "./Footer";
import Intervenants from "../Intervenants";
import Topbar from "./Topbar";

export default function LayoutPage() {

    const location = useLocation()
    const componentToDisplay = () => {

        if (location.pathname !== undefined && location.pathname) {

            switch(location.pathname) {

                case null:
                    return "NULL DANS LE STATE";
                case "/programme":
                    return <Programme/>;
                case "/intervenants":
                    return <Intervenants/>;
                case "/directs":
                    return <Directs/>;
                case "/replays":
                    return <Replays/>;

                default:
                    return <h1>Nothing</h1>
            }
        } else {
            return "Composant non défini"
        }


    }


 return (
    <>
        <div className={"d-block d-md-none"}><Topbar/></div>

        <div className="container-fluid digiLayout">
            <div className="row">

                <nav id="sidebarMenu" className="navbar navbar-expand-lg d-none d-md-block col-md-2 p-3 sidebar d-flex flex-column justify-content-between digiLeftBar">
                    <SidebarLeft />
                </nav>

                <main className="col-md-10 ms-sm-auto p-0 digiContentArea">
                    <div id="digiContent">
                        {componentToDisplay()}
                    </div>
                    <Footer />
                </main>

            </div>
        </div>

    </>
 )}