import React from 'react'

export default function JwplayerEmbedVideo() {

    let styleContainerIframe = {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
    }

    let styleIframe = {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        background: false,
    }

    return (
     <div style={styleContainerIframe}>
         <iframe src="https://cdn.jwplayer.com/players/iSgKtiCY-0Z7OXC7b.html"
                 width="100%" height="100%"
                 frameBorder={0}
                 scrolling="auto"
                 title="SIAO - Protection judiciaire des majeurs vulnérables" style={styleIframe}
                 allowFullScreen={true}></iframe>
     </div>
    )
}