import React from 'react'
import './SidebarLeft.css'
import {NavLink} from "react-router-dom";
import Logo from "../Layout/Logo";
import LogoPrefete from "../Layout/LogoPrefete";

export default function SidebarLeft() {
 return (
     <>

         <div className="sidebarTop">
             <div className="mt-5 sidebarLogo">
                 <LogoPrefete />
             </div>

             <ul className="mt-5 mb-5 digiNav">
                 <li>
                    <NavLink exact activeClassName="current" to="/">Accueil</NavLink>
                 </li>
                 <li>
                     <NavLink exact activeClassName="current" to="/programme">Programme</NavLink>
                 </li>
                 <li>
                     <NavLink exact activeClassName="current" to="/intervenants">Intervenants</NavLink>
                 </li>
                 {/*<li>*/}
                 {/*    <NavLink exact activeClassName="current" to="/directs">Suivre la conférence</NavLink>*/}
                 {/*</li>*/}
                 <li>
                     <NavLink exact activeClassName="current" to="/replays">Replays</NavLink>
                 </li>
             </ul>

             <Logo />

         </div>
     </>

 )}