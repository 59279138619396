import React from 'react'

export default function ChatBox() {

    let stylesChatBox = {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%'
    }

    let styleChatIframe = {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
    }

 return (
     <div style={{stylesChatBox}}>
         <iframe title="Salon de discussion du SIAO"
                 width="100%" height="432"
                 src="https://chat-48000siao.portes-ouvertes-virtuelles.fr/channel/conference-siao48?layout=embedded"
                 style={{styleChatIframe}}
                 scrolling="no" frameBorder="0" allowFullScreen={true} webkitallowfullscreen={"true"} mozallowfullscreen={"true"}
                 oallowfullscreen={"true"} msallowfullscreen={"true"}></iframe>
     </div>
 )}