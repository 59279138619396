import React from 'react'
import './Intervenants.css'

export default function Intervenants() {
    return (
        <section id="digiContentIntervenants">

            <h1 className="digiTitreGauche">Les intervenants de la conférence</h1>

            <article className="digiIntervenant">
                <div className="row">
                    <div className="col-lg-4">
                        <h2 className={"h1"}>Patrice<br/>ORLHAC</h2>
                        <h3>Association Tutélaire de Lozère</h3>
                    </div>
                    <div className="col-lg-8 digiDescriptionIntervenant">

                        <p>Investi depuis 20 ans dans l'accompagnement des personnes vulnérables, mon parcours est celui d'un acteur de terrain mettant au service de chacun son expérience acquise au fil des ans au sein de l'Association Tutélaire de Lozère (ATL) : accompagnateur social, technicien de l'intervention sociale, animateur du dispositif de soutien aux tuteurs familiaux, chargé de la démarche qualité, mandataire à la protection des majeurs et référent pédagogique.</p>

                    </div>
                </div>
            </article>

            <article className="digiIntervenant">
                <div className="row">
                    <div className="col-lg-4">
                        <h2 className={"h1"}>Bruno<br/>FOUCARD</h2>
                        <h3>Intervenant spécialisé dans l'accompagnement des acteurs du médicosocial et du social (Projets – Organisation – Management)</h3>
                    </div>
                    <div className="col-lg-8 digiDescriptionIntervenant">

                        <p>Directeur Adjoint–Conseiller Technique au CREAI-ORS Occitanie (Centre Régional d’Etudes d'Actions et d'Informations en faveur des personnes en situation de vulnérabilité – Observatoire Régional de la Santé) de 2008 à 2020. Appui à la mise en œuvre du Schéma Régional des Mandataires Judiciaires à la Protection des Majeurs, piloté par la DRJSCS de 2017 à 2020</p>


                    </div>
                </div>
            </article>
            
            <article className="digiIntervenant">
                <div className="row">
                    <div className="col-lg-4">
                        <h2 className={"h1"}>Frédéric<br/>ITIER</h2>
                        <h3>Mandataire Judiciaire à la Protection des Majeurs</h3>
                    </div>
                    <div className="col-lg-8 digiDescriptionIntervenant">

                        <p>Mandataire Judiciaire à la Protection des Majeurs (MJPM) depuis plus de 20 ans dont 10 ans en tant que chef de service dans un Service Mandataire dans l'Hérault et MJPM Indépendant depuis 6 ans dans l'Hérault et le Gard. Frédéric ITIER est aussi formateur au CNC-MJPM.</p>


                    </div>
                </div>

            </article>

        </section>
    )}