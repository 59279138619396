import React from 'react'
import Logo from "./Logo"
import './Footer.css'

export default function Footer() {
 return (
     <>
        <footer>
            <div className="row p-0 m-0 d-flex justify-content-center mb-2">
                <div className="col digiFooterBox">

                    <h4>Nous<br/>contacter</h4>
                    <ul>
                        <li>04.30.11.10.43</li>
                    </ul>
                </div>
                <div className="col d-flex flex-column justify-content-center digiFooterBox">
                    <address className="d-inline-block m-0">
                        Halle Saint Jean<br/>
                        16B Avenue des Gorges du Tarn<br/>
                        48000 MENDE
                    </address>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <ul className={"digiListeLogosPartenaires d-flex flex-wrap justify-content-between align-items-center m-0 p-0 mt-3 mb-3"}>
                    <li>
                        <Logo
                            file={"LogoUdaf.png"}
                            alt={"UDAF Lozère - Unis pour les familles"}
                        />
                    </li>
                    <li>
                        <Logo
                            file={"LogoAtal.png"}
                            alt={"ATAL Association Tutélaire Aveyron Lozère"}
                        />
                    </li>
                    <li>
                        <Logo
                            file={"LogoAtl.png"}
                            alt={"ATL48 Association Tutélaire de Lozère"}
                        />
                    </li>
                    <li>
                        <Logo
                            file={"LogoAmji.png"}
                            alt={"AMJI"}
                        />
                    </li>
                </ul>
            </div>
        </footer>
         <div className="row d-flex align-items-center m-0 mb-5 p-0 digiMentions">
             <div className="col-12 text-center">
                 <a href="https://www.digitalyz.fr" title="Agence Web - Solutions de LiveStreaming" target="_blank" rel="noreferrer">
                    <img src="/assets/images/logo-digitalyz.svg" alt="Logo Agence Digitalyz - Solutions de LiveStreaming" />
                 </a>
             </div>
             <div className="col-12">
                 <p className={"text-center"}><a href="https://www.digitalyz.fr" title="Agence Web - Solutions de LiveStreaming" target="_blank" rel="noreferrer">Site web et solutions d'évènements virtuels créés par Digitalyz</a> - 09 72 16 91 58</p>
             </div>
         </div>
     </>
 )}