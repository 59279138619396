import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import Logo from "./Logo";
import "./Topbar.css";

export default function Topbar() {

    const [menu, setMenu] = useState(false)
    const [show, setShow] = useState("")

    const toggleMenu = () => {
        if( menu ) {
            setMenu(false)
            setShow( "" )
        } else {
            setMenu(true)
            setShow( "show" )
        }
    }

return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light text-center digiTopNavbar">
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={"collapse navbar-collapse " + show}>
                <div className="navbar-nav">
                    <div className="sidebarTop">
                        <div className="mt-5 sidebarLogo text-center">
                            <Logo
                                file={"prefete_de_la_lozere.png"}
                                alt={"Préfète de la Lozère"}
                                width={"15%"}
                            />
                        </div>

                        <ul className="mt-5 mb-5 digiNav">
                            <li>
                                <NavLink exact activeClassName="current" to="/">Accueil</NavLink>
                            </li>
                            <li>
                                <NavLink exact activeClassName="current" to="/programme">Programme</NavLink>
                            </li>
                            <li>
                                <NavLink exact activeClassName="current" to="/intervenants">Intervenants</NavLink>
                            </li>
                            <li>
                                {/*<NavLink exact activeClassName="current" to="/directs">Suivre la conférence</NavLink>*/}
                            </li>
                            <li>
                                <NavLink exact activeClassName="current" to="/replays">Replays</NavLink>
                            </li>
                        </ul>

                        <div className={"text-center mb-4"}>
                            <Logo
                            width={"15%"}
                        />
                        </div>

                    </div>
                </div>
            </div>
        </nav>
    </>
 )}