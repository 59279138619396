import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import LayoutHome from "./Components/Layout/LayoutHome";
import LayoutPage from "./Components/Layout/LayoutPage";

function App() {

  return (
    <>
        <Router>

            <Switch>
                <Route path="/" exact component={LayoutHome}/>
                <Route path="/" component={LayoutPage}/>
            </Switch>

        </Router>
    </>
  );
}

export default App;
