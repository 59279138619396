import React from 'react'

import './Home.css'
import ButtonDiscoverEvent from "./Button/ButtonDiscoverEvent";
import Logo from "./Layout/Logo";

export default function Home() {
 return (
    <>
        <main id={"digiHomePage"} className="container-fluid p-5 d-flex align-items-center">
            <div className="row d-flex align-items-center">
                <div className="col-md-5">
                    <div className="d-flex flex-column justify-content-between">
                        <div className={"mb-5 digiEntete"}>
                            <h1>Protection judiciaire des majeurs vulnérables</h1>
                            <ButtonDiscoverEvent
                                date={"14 octobre 2021"}
                                to={"programme"}
                                label={"Découvrir l'évènement"}
                            />
                        </div>
                        <div className="mt-5 digiListeLogosPartenaires">
                            <ul className={"d-flex justify-content-between"}>
                                <li>
                                    <Logo
                                        file={"LogoUdaf.png"}
                                        alt={"UDAF Lozère - Unis pour les familles"}
                                    />
                                </li>
                                <li>
                                    <Logo
                                        file={"LogoAtal.png"}
                                        alt={"ATAL Association Tutélaire Aveyron Lozère"}
                                    />
                                </li>
                                <li>
                                    <Logo
                                        file={"LogoAtl.png"}
                                        alt={"ATL48 Association Tutélaire de Lozère"}
                                    />
                                </li>
                                <li>
                                    <Logo
                                        file={"LogoAmji.png"}
                                        alt={"AMJI"}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 digiDiscours">
                    <section className="digiDiscoursPrincipal">
                        <div className="row">
                            <div className="col-8 p-3">

                                <p>En France, environ 730 000 personnes majeures ne sont pas en mesure de pourvoir à leurs intérêts en raison d'une altération de leurs facultés mentales ou corporelles et font l'objet d'une mesure de protection judiciaire. Ces mesures exercées par la famille ou des professionnels ont pour finalité de protéger les intérêts les personnes vulnérables tout en lui garantissant la plus large autonomie possible.</p>
                                <p>L'article 428 du Code civil (réécrit par la loi n° 2019-222 du 23 mars 2019 de programmation 2018-2022 et de réforme pour la justice) dispose qu'une mesure de protection judiciaire ne peut être ordonnée par le juge qu'en cas de nécessité et « lorsqu'il ne peut être suffisamment pourvu aux intérêts de la personne par la mise en œuvre du mandat de protection future (...) ».</p>
                                <p>Cette journée a pour objectif de mieux définir le métier de Mandataire Judiciaire à la Protection des Majeurs (MJPM) afin d’appréhender les contours et limites du cadre d’intervention de ces professionnels. Il s'agit donc d'améliorer la coordination des partenaires intervenant autour de la personne protégée pour une meilleure prise en charge.</p>
                                <p className={"text-end fw-bold"}>Valérie HATSCH, préfète de la Lozère</p>

                                <blockquote>« Quiconque est conduit par la raison désire pour les autres ce qu'il désire pour lui-même. » - <i>Spinoza</i></blockquote>



                            </div>
                            <div className="col-4 p-3">
                                {/*<img src={"/assets/images/valerie-hatsch-prefete-lozere.jpg"} alt={"Valérie HATSCH - Préfète de la Lozère"} className={"w-100"}/><br/>*/}
                                <Logo
                                    file={"prefete_de_la_lozere.png"}
                                    alt={"Préfète de la Lozère"}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="digiDiscoursSecondaire">
                        <div className="row">
                            <div className="col-8">

                                <p>Depuis plus de 10 ans, le service intégré d’accueil et d’orientation (SIAO) est implanté en Lozère pour mettre en adéquation l’offre et la demande d’hébergement du territoire. La mise en réseau des différents acteurs de l’hébergement est le pilier de cette mission qui se traduit par un portage d’un collectif associatif du SIAO.</p>
                                <p>En Lozère, nous avons été soutenus par les services de l’État pour aller plus loin et proposer des journées partenariales qui s’adressent aux secteurs du social, médico-social et sanitaire. Aujourd’hui, c’est dans ce cadre-là que nous vous invitons à échanger autour de la mission des mandataires judiciaires à la protection des majeurs (MJPM). Seule une bonne coordination des professionnels permettra de mener à bien notre intérêt commun : l’accompagnement des personnes.</p>

                                <p className={"text-end fw-bold"}>Maxime BARILLOT, président du Collectif SIAO48</p>

                                <blockquote>« La seule voie qui offre quelque espoir d’un avenir meilleur pour toute l’humanité est celle de la coopération et du partenariat. » - <i>Kofi Annan</i></blockquote>

                            </div>
                            <div className="col-4">
                                <Logo />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </>
 )}